<template>
  <div class="table">
    <!-- 表格 -->
    <el-card shadow="never">
      <div slot="header" class="flex" style="align-items: center;">
        <!-- <span>备课数据</span> -->
        <span>课程目录</span>
        <div class="flex_1"></div>
        <text-button
          icon="el-icon-refresh"
          contentTit="刷新"
          @click.native="search"
        ></text-button>
        <text-button
          icon="el-icon-setting"
          contentTit="列设置"
          :colSetting="colSetting"
          @update="update"
          :ispopover="true"
          :baseColSetting="baseColSetting"
        ></text-button>
      </div>
      <el-row style="margin-top: 20px;">
        <el-col :span="6">
          <el-card>
            <el-tree
            :data="treeList"
            highlight-current
            :props="defaultProps"
            @node-click="handleNodeClick"
            style="padding-top: 20px;padding-bottom: 20px;"
            default-expand-all
            :default-checked-keys="deId"
            :current-node-key="currentId"
            node-key="id"
            ref="tree"
            ></el-tree>
          </el-card>
        </el-col>
        <el-col :span="16" :offset="1">
          <el-card   style="margin-bottom: 20px;">
          <el-table-bar fixed :static="true">
            <el-table
              :key="tableKey"
              :data="dataList"
              fit
              :highlight-current-row="true"
            >
              <el-table-column
                v-for="item of defaultHeader"
                :key="item.label"
                :label="item.label"
                :align="item.align ? item.align : 'left'"
                :min-width="item.width ? item.width : 'auto'"
                :prop="item.prop"
                :show-overflow-tooltip="true"
              >
              <template slot-scope="scope">
                  <template v-if="item.label === '课节名称'">
                    <span v-if="scope.row.title">
                      {{ scope.row.title }}
                    </span>
                    <span v-else>--</span>
                  </template>
                <template v-if="item.label === '上课时长'">
                    <span>
                      {{ scope.row.standardMinutes }} min
                    </span>
                </template>
                  <template v-else-if="item.label === '状态'">
                    <span
                      class="span-state"
                      :style="
                        'backgroundColor' + ':' + getPreparColor(scope.row.prepare)
                      "
                    ></span>
                    <span v-text="getPreparColorText(scope.row.prepare)"></span>
                  </template>
                  <template v-else-if="item.label === '操作'">
                    <el-button type="text" @click="prepareLessonDetail(scope.row)">去备课</el-button>
                  </template>
                  <span v-else>{{ scope.row[item.prop] }}</span>
                </template>
              </el-table-column>
            </el-table>
          </el-table-bar>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pagination.currentPage"
            :page-sizes="pagination.pageSizes"
            :page-size="pagination.pageSize"
            :layout="pagination.layout"
            :total="pagination.total"
          ></el-pagination>
          </el-card>
        </el-col>
      </el-row>
    </el-card>
    <change-pass :show="changePassDialog" @close="changePassDialog=false" @savePass="savePass" :showClose='showClose' :closeModal='closeModal'></change-pass>
  </div>
</template>

<script>
  import changePass  from '../../views/public/user/ChangePass/index'
  import TextButton from '@/components/TextButton'
  import SearchQuery from '@/components/SearchQuery'
  import getPreparColor from '@/mixins/getStateColor'
  import getPreparColorText from '@/mixins/getStateColor'
  import {getCourseByUser,courseUnitList} from '@/apiteacher/teacher'
  import {userEditPwd,merchantPasswordCheck} from '@/api/user'

  // 默认展示列
  const baseColSetting = [
    {
      label: '课次',
      prop: 'sortIndex',
      state: true,
      align: 'center',
    },
    {
      label: '课节名称',
      prop: 'center',
      state: true,
      align: 'center',
      width: '100',
    },
    {
      label: '状态',
      prop: 'status',
      state: true,
      align: 'center',
      width: '100',
    },
    {
      label: '上课时长',
      prop: 'standardMinutes',
      state: true,
      align: 'center',
      width: '150',
    },
    {
      label: '操作',
      state: true,
      align: 'center',
      width: '130',
    },
  ]
  export default {
    components: { TextButton, SearchQuery ,changePass},

    mixins: [getPreparColor,getPreparColorText],
    computed: {
      defaultHeader () {
        return this.colSetting.filter(item => item.state)
      },
    },
    
    watch:{
      deId:{
        handler(val){
          this.deId = val
        },
        immediate:true,
      }
    },
    data () {
      return {
        showClose:false,
        closeModal:true,
        changePassDialog:false,
        deId:[],
        treeList:[],
        defaultProps: {
          children: 'children',
          label: 'name',
        },
        dataList: [],
        tableKey: 1,
        currentId:'',
        // options: [{
        //   value: '可备课',
        //   label: '可备课'
        // }, {
        //   value: '不可备课',
        //   label: '不可备课'
        // }],
        // queryParam: {
        //   // 查询参数
        //   name: '',
        //   status: ''
        // },
        colSetting: JSON.parse(JSON.stringify(baseColSetting)), // 表格展示列
        baseColSetting: JSON.parse(JSON.stringify(baseColSetting)),
        pagination: {
          currentPage: 1,
          pageSize: 10,
          pageSizes: [10, 20, 30, 40],
          layout: 'total, sizes, prev, pager, next, jumper',
          total: 0,
        },
        classOrderBy: 'openDate', // 排序依据
        classIsAsc: false, // 默认为升序
        baseInfo: {
          ci: null,//课程id
          cn: null,//课程名称
          ui: null,//课节id
          un: null,//课节名称
        }
      }
    },
    mounted(){
      let passSafe = localStorage.getItem('passSafe')
      if(passSafe === 'N'){
        this.changePassDialog = true
      }
      
      this.getCourseByUser()

    },
    methods: {
    // 保存密码
      async savePass (data) {
        let passData = {
          pwdOld: data.originalPass,
          pwdNew: data.newPass
        }
        // 密码校验
        const formdate = new FormData();
        formdate.append('password',  data.newPass,)
        const res = await merchantPasswordCheck(formdate)
        if(!res.body){
          window.$msg('当前新密码不符合密码规范，请重新填写密码', 2)
          return
        }
        await userEditPwd({ ...passData })
        this.$store.dispatch('user/loginOut').then(() => {
          window.$msg('密码修改成功,请重新登录', 2)
          this.close()
          this.changePassDialog = false
          // clearCookie('token')
          // localStorage.clear()
          // this.$router.push('/login')
        })
      },
      handleNodeClick(data) {
        localStorage.setItem('currentCourseInfo',JSON.stringify(data));
        if(data.parentId !== 0) {
          this.currentId = data.id;
          this.baseInfo.ci = data.id;
          this.baseInfo.cn = data.name;
          this.currCourseId = data.id;
          this.courseUnitList();
        }
      },
      // 清空选项
      // clearQuery () {
      //   this.queryParam = {
      //     status: '',
      //     name:''
      //   }
      // },

      search () {
        this.pagination.currentPage = 1
        this.courseUnitList()
      },

      // 获取全部班级列表
      async getCourseByUser () {
        const res = await getCourseByUser({})
        this.treeList = res.body
        if(this.treeList.length !== 0) {
          this.currentId = this.treeList[0].children[0].id;
          this.deId = [this.treeList[0].children[0].id];
          localStorage.setItem('currentCourseInfo',JSON.stringify(this.treeList[0].children[0]));
          this.currCourseId = this.treeList[0].children[0].id;
          this.baseInfo.ci = this.treeList[0].children[0].id;
          this.baseInfo.cn = this.treeList[0].children[0].name;
          this.courseUnitList();
          this.$nextTick(()=>{
            this.$refs.tree.setCurrentKey(this.treeList[0].children[0].id);
          })
        }
      },

      // 获取全部班级列表
      async courseUnitList () {
        this.dataList = []
        const res = await courseUnitList({
          pageNum:this.pagination.currentPage,
          pageSize:this.pagination.pageSize,
          needCount:true,
          courseId:this.currentId
        })
        this.dataList = res.body.list
        this.pagination.total = res.body.total

        this.dataList.forEach((item,index)=>{
          if(this.pagination.currentPage>1){
            item.sortIndex = (this.pagination.currentPage-1) * this.pagination.pageSize + index +1
          }
          else{
            item.sortIndex = index + 1
          }
        })
      },

      prepareLessonDetail (row) {
        this.currUnitId = row.id;
        localStorage.setItem('currentCourseUnitInfo',JSON.stringify(row));
        this.baseInfo.ui =  row.id;
        this.baseInfo.un =  row.title;
        if(row.prepare === 'N') {
          window.$msg('暂无此课件的备课权限',2)
          return false
        }
        let routeData = this.$router.resolve({
          path: '/teacher/prepareLesson-detail',
          query: {
            courseUnitId: row.id,
            courseTitle: row.title,
            ...this.baseInfo,
          },
        })
        window.open(routeData.href, '_blank')
      },

      update (data) {
        this.tableKey = this.tableKey + 1
        if (data.lenght !== 0) {
          this.colSetting.forEach(item => {
            item.state = false
            data.forEach(ele => {
              if (ele === item.label && !item.state) {
                item.state = true
              }
            })
          })
        } else {
          this.colSetting = []
        }
      },

      // 分页
      handleCurrentChange (val) {
        this.pagination.currentPage = val
        this.courseUnitList()
      },
      handleSizeChange (val) {
        this.pagination.pageSize = val
        this.courseUnitList()
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import '../../style/container.scss';
  @import "../../style/table.scss";
  /deep/ .el-form--inline .el-form-item {
    margin-right: 0;
  }
  .is-current{
    background-color: #eee;
  }
</style>
